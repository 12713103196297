@import url(https://cdn.jsdelivr.net/npm/@xz/fonts@1/serve/plus-jakarta-display.min.css);
/* styles/toasts.css */
.toast-message {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    margin-bottom: 10px;
    color: #fff;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    font-size: 16px;
    animation: fadeIn 0.5s, fadeOut 0.5s 2.5s;
  }
  
  .toast-message.success {
    background-color: #4caf50; /* Green */
  }
  
  .toast-message.error {
    background-color: #f44336; /* Red */
  }
  
  .toast-message.info {
    background-color: #2196f3; /* Blue */
  }
  
  .toast-message.warning {
    background-color: #ff9800; /* Orange */
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes fadeOut {
    from {
      opacity: 1;
      transform: translateY(0);
    }
    to {
      opacity: 0;
      transform: translateY(-10px);
    }
  }

